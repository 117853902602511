import { Dialog,DialogContent, DialogTitle, Grid, List, ListItem, Paper, Typography, styled } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { toast } from "react-toastify";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link } from "react-router-dom"
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const columns = [
  { field: "_id", headerName: "ID", width: 200, editable: false },
  { field: "SentBy", headerName: "Sent By", width: 200, editable: false, renderCell: (params) => { return params.row.SentBy ? (<Typography style={{ display: 'flex', alignItems: 'center', justifyContent: 'cetner', height: '100%'}}>{params.row.SentBy?.Constants.fName} <Link to={`/UserProfile/${params.row.SentBy._id}`} target = "blank"><OpenInNewIcon style={{ width: '15px', height: '15px', color: 'grey'}} /></Link></Typography>) : (<Typography>User Deleted</Typography>)},  },
  { field: "ReceivedBy", headerName: "Received By", width: 200, editable: false, renderCell: (params) => { return params.row.ReceivedBy ? (<Typography style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>{params.row.ReceivedBy?.Constants.fName} <Link to={`/UserProfile/${params.row.ReceivedBy._id}`} target = "blank"><OpenInNewIcon style={{ width: '15px', height: '15px', color: 'grey'}} /></Link></Typography>) : (<Typography>User Deleted</Typography>)} },
  { field: "ScheduledDate", headerName: "Scheduled Date", width: 120, editable: false, valueGetter: (params) => { return new Date(params.row.ScheduledTime.date).toLocaleDateString()}},
  { field: "ScheduledTime", headerName: "Scheduled Time", width: 120, editable: false, valueGetter: (params) => { return `${params.row.ScheduledTime.from.hour < 10 ? "0" + params.row.ScheduledTime.from.hour : params.row.ScheduledTime.from.hour}:${params.row.ScheduledTime.from.min < 10 ? params.row.ScheduledTime.from.min + "0" : params.row.ScheduledTime.from.min} ${params.row.ScheduledTime.from.AMPM}` }},
  { field: "Meeting", headerName: "Meeting Set", width: 200, editable: false, valueGetter: (params) => { return params.row.Link != null ? "Yes": "No"} },
  { field: "MeetingL", headerName: "Meeting Link", width: 200, editable: false, renderCell: (params) => { return params.row.Link && ( <Link to={params.row.Link} target="blank">Meeting Link</Link>) } },
  { field: "CShare", headerName: "Contact Shared", width: 200, editable: false, valueGetter: (params) => { return params.row.ContactShare && (params.row.ContactShare.Auser.flag > 0 && params.row.ContactShare.Buser.flag > 0) ? "Yes":"No" } },

]
const Meetings = () => {
  const [meetings, setMeetings] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchMeetingsData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER}adm/meetings`
        );
        setMeetings(res.data.data);
        console.log(res);

        setLoading(false);
      } catch (error) {
        toast.error(error.response.data.message);
        setLoading(false);
      }
    };
    fetchMeetingsData();
  }, []);

  const requestsFilter = (data) => {
    return data.filter(entry => entry.Link == null);
  }
  const meetsFilter = (data) => {
    return data.filter(entry => entry.Link != null);
  }
  const completedFilter = (data) => {
    return data.filter(el => new Date(el.EndTime).getTime() < new Date().getTime());
  }
  const upcomingFilter = (data) => {
    return data.filter(el => new Date(el.EndTime).getTime() >= new Date().getTime()); 
  }

  const handleDialog = (data) => {
    setData(data);
    setOpen(true);
  }
  return loading || meetings === null ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      Loading...
    </div>
  ) : (
    <div style={{ width: "100vw", height: "100%", display: "flex", padding: '10px', flexDirection: 'column' }}>
        <Typography>Info: Click on the tiles to get all entries in that field</Typography>
      <Grid container spacing={2} >
        <Grid item xs={12} sm={6} md={4} onClick = {() => handleDialog(meetings)}>
          <Item style={{ height: "300px", display: "flex", alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 10 }}>
            <Typography variant="h5">Total Meetings/Requests</Typography>
            <Typography variant="h1" >
              {meetings.length}
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={4} onClick = {() => handleDialog(requestsFilter(meetings))}>
          <Item style={{ height: "300px", display: "flex", alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 10 }}>
            <Typography variant="h5">Requests</Typography>
            <Typography variant="h1" >
              {requestsFilter(meetings).length}
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={4} onClick = {() => handleDialog(meetsFilter(meetings))}>
          <Item style={{ height: "300px", display: "flex", alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 10 }}>
            <Typography variant="h5">Meets</Typography>
            <Typography variant="h1" >
              {meetsFilter(meetings).length}
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={4} onClick = {() => handleDialog(completedFilter(meetings))}>
          <Item style={{ height: "300px", display: "flex", alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 10 }}>
            <Typography variant="h5">Completed Meetings</Typography>
            <Typography variant="h1" >
              {completedFilter(meetings).length}
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={4} onClick = {() => handleDialog(upcomingFilter(meetings))}>
          <Item style={{ height: "300px", display: "flex", alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 10 }}>
            <Typography variant="h5">Upcoming Meetings</Typography>
            <Typography variant="h1" >
              {upcomingFilter(meetings).length}
            </Typography>
          </Item>
        </Grid>
      </Grid>

      <Dialog open = {open} sx={{ "& .MuiDialog-paper": {maxWidth: '80vw'}}} onClose={() => setOpen(false)} >
        <DialogTitle>
            Meetings Information
        </DialogTitle>
        <DialogContent>
        <DataGrid
            rows={data}
            columns={columns}
            getRowId={(row) => row._id}
            initialState={{
              sorting: {
                sortModel: [{ field: "createdAt", sort: "desc" }],
              },
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[9]}
            checkboxSelection
            disableRowSelectionOnClick
            disableColumnFilter
            disableDensitySelector
            disableColumnSelector
            onSelectionChange={(e) => {
              setSelectedRows(e.selectionModel);
            }}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Meetings;
