import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Drawer,
  Button,
  ButtonGroup,
  List,
  ListItem,
  ListItemButton,
  Divider,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Toolbar,
  AppBar,
  CssBaseline,
} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import PostAddIcon from "@mui/icons-material/PostAdd";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

function Navo() {
  const decoded = jwtDecode(localStorage.getItem("userToken"));
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER}adm/FetchAdm/${decoded.id}`
        );
        setUserData(await response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [decoded.id]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [state, setState] = useState({ right: false });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Card sx={{ maxWidth: 345 }}>
        <div style={{ width: '100%' , display: 'flex', alignItems: 'center', justifyContent: 'center' , height: '100px'}}>
          <img src="./BLY.png" height={50} />
        </div>
        <CardContent align="center">
          <Typography gutterBottom variant="h5" component="div">
            {userData ? userData.username : "Admin"}
            <Typography
              variant="h5"
              component="sup"
              sx={{ fontSize: "0.5em", color: "grey" }}
            >
              {userData ? userData.Role : ""}
            </Typography>
          </Typography>
          <Box display="flex" justifyContent="center">
            <ButtonGroup
              variant="outlined"
              size="small"
              aria-label="small button group"
              sx={{ color: "#231b7e" }}
            >
              <Button
                onClick={handleLogout}
                sx={{
                  color: "#231b7e",
                  ":hover": {
                    backgroundColor: "#231b7e",
                    color: "white",
                    borderColor: "#231b7e",
                  },
                  borderColor: "#231b7e",
                }}
              >
                Logout
              </Button>
              <Button
                component={Link}
                to={"/MyProfile"}
                sx={{
                  color: "#231b7e",
                  ":hover": {
                    backgroundColor: "#231b7e",
                    color: "white",
                    borderColor: "#231b7e",
                  },
                  borderColor: "#231b7e",
                }}
              >
                My Profile
              </Button>
            </ButtonGroup>
          </Box>
        </CardContent>
      </Card>
      <List>
        <ListItem
          disablePadding
          component={Link}
          to={"/dash"}
          sx={{ color: "black" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <SpaceDashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          component={Link}
          to={"/UserExplorer"}
          sx={{ color: "black" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <PeopleAltIcon />
            </ListItemIcon>
            <ListItemText primary="Matched users" />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          component={Link}
          to={"/Reports"}
          sx={{ color: "black" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <PostAddIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          component={Link}
          to={"/Meetings"}
          sx={{ color: "black" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <PeopleAltIcon />
            </ListItemIcon>
            <ListItemText primary="Meetings" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding
          component={Link}
          to={"/Logs"}
          sx={{ color: "black" }}>
          <ListItemButton>
            <ListItemIcon>
              <AdminPanelSettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Admin Space" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    setAnchorEl(null);
    window.location.href = "/";
  };

  return (
    <CssBaseline>
      <AppBar position="sticky" style={{ backgroundColor: "#231b7e" }}>
        <Toolbar>
          <Typography
            variant="h6"
            style={{ textDecoration: "none", color: "white" }}
            sx={{ flexGrow: 1 }}
            align="center"
          >
            Admin-Butter
            <sup style={{ fontSize: ".65em" }}>
              {process.env.REACT_APP_VERSION}
            </sup>
          </Typography>
          <Button sx={{ color: "white" }} onClick={toggleDrawer("right", true)}>
            <MenuIcon />
          </Button>
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
          >
            {list("right")}
          </Drawer>
          {/* <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                 vertical: 'top',
                 horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                 vertical: 'top',
                 horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose} component={Link} to={'/Settings'}>
                Settings
                </MenuItem>
                <MenuItem onClick={handleClose} component={Link} to={'/MyProfile'}>
                My Profile
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                Log Out
                </MenuItem>
              </Menu>
            </div> */}
        </Toolbar>
      </AppBar>
    </CssBaseline>
  );
}

export default Navo;
