import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import Card from "@mui/material/Card";
import { useEffect } from "react";
import { Avatar, AvatarGroup, Box, CardContent, Typography } from "@mui/material";
import { toast } from "react-toastify";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import FaceIcon from "@mui/icons-material/Face";
import TransgenderIcon from "@mui/icons-material/Transgender";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { Link } from "react-router-dom";
const UserInfo = ({ open = false, UserDetail, handleDrawerClose }) => {
  const [dp, setDP] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stackData, setStackData] = useState([]);

  useEffect(() => {
    fetchDP();
    console.log(UserDetail);
    fetchMatches();
  }, [UserDetail]);
  const fetchDP = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}m/dp/${UserDetail.id}`
      );
      if (response) {
        setDP(response.data);
      }
      setLoading(false);
    } catch (error) {
      // toast.error(error.response.data.message);
      setDP(null)
      setLoading(false);
    }
  };
  const fetchMatches = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}m/ReturnStack/${UserDetail.id}`
      );
      await setStackData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setStackData([])
    }
  };
  function calculateBMI(Height, Weight) {
    return Math.round((Weight / Math.pow(Height / 100, 2)) * 100) / 100;
  }
  function calculateAge(birthDate) {
    let birth = new Date(birthDate);
    let now = new Date();
    let age = now.getFullYear() - birth.getFullYear();
    let monthDifference = now.getMonth() - birth.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && now.getDate() < birth.getDate())
    ) {
      age--;
    }
    return age;
  }

  return (
    UserDetail && (
      <Box sx={{ width: '100%' }}>
        <Drawer
          open={open}
          anchor="bottom"
          sx={{ ".css-9emuhu-MuiPaper-root-MuiDrawer-paper":{
            height: '70vh',
          },
        position: 'relative'}}
          onClose={handleDrawerClose}
          >
              <CloseIcon
                onClick={handleDrawerClose}
                style={{ position: "absolute", top: 10, left: 10 }}
              />
          <Card
          sx={{
            "&.MuiCard-root":{
              overflow: 'initial'
            }
          }}
            style={{
              boxShadow: "none",
              // marginBottom: '5vh',
              display: "flex",
              alignItems: "center",
              alignSelf: "center",
              flexDirection: "column",
              minWidth: "300px",
              // marginTop: "20px",
              
            }}
          >
            <AvatarGroup>
            <Avatar
              src={loading ? null : dp}
              alt={UserDetail.row.Constants.username}
              style={{ width: "150px", height: "150px" }}
              />
              </AvatarGroup>
            <CardContent style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography fontSize={24}>
                  {UserDetail.row.fName ? UserDetail.row.fName : 'Not Filled'} {UserDetail.row.lName ? UserDetail.row.lName : 'Not Filled'}
                </Typography>
                <Typography fontSize={24}>
                  {calculateAge(UserDetail.row.Constants.DOB)}
                </Typography>
              </div>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                  <Typography><strong>Profile Created on:</strong> {new Date(UserDetail.row.createdAt).toLocaleDateString()}</Typography>
                  <Typography><strong>Last Update:</strong> {new Date(UserDetail.row.updatedAt).toLocaleDateString()}</Typography>
                </div>
              <Typography variant="h5" marginTop={"15px"}>
                Personal Info
              </Typography>
              <div style={{}}>
                <Typography display={"flex"} alignItems={"center"} gap={2}>
                  <EmailIcon /> {UserDetail.row.Constants.email}
                </Typography>
                <Typography display={"flex"} alignItems={"center"} gap={2}>
                  <PhoneIcon /> {UserDetail.row.Constants.phone}
                </Typography>
                <Typography display={"flex"} alignItems={"center"} gap={2}>
                  {UserDetail.row.Constants.gender === "male" ? (
                    <MaleIcon />
                  ) : UserDetail.row.Constants.gender === "female" ? (
                    <FemaleIcon />
                  ) : (
                    <TransgenderIcon />
                  )}{" "}
                  {UserDetail.row.Constants.gender}
                </Typography>
              </div>

              <Typography variant="h5" marginTop={"15px"}>
                BMI
              </Typography>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography>
                    <strong>AtrScore:</strong> {UserDetail.row.AtrScore ? UserDetail.row.AtrScore : "Not Filled"}
                  </Typography>
                  <Typography>
                    <strong>Height:</strong> {UserDetail.row.BMI ? UserDetail.row.BMI.Height : 'Not Filled'}
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>
                    <strong>Weight:</strong> {UserDetail.row.BMI ? UserDetail.row.BMI.Weight : 'Not Filled'}
                  </Typography>
                  <Typography>
                    <strong>BMI:</strong>{" "}
                    {UserDetail.row.BMI && calculateBMI(
                      UserDetail.row.BMI.Height,
                      UserDetail.row.BMI.Weight
                    )}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>
                    <strong>CAS:</strong> {UserDetail.row.CAS ? UserDetail.row.CAS : 'Not Filled'}
                  </Typography>
                  <Typography>
                    <strong>SES:</strong>{" "}
                    {UserDetail.row.SES ? UserDetail.row.SES : 'Not Filled'}
                  </Typography>
                </div>
              </div>
              <Typography variant="h5" marginTop={"15px"}>
                Location
              </Typography>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography>
                    <strong>Area:</strong> {UserDetail.row.Location.Area ? UserDetail.row.Location.Area : "Not Filled"}
                  </Typography>
                  <Typography>
                    <strong>Area Pin:</strong> {UserDetail.row.Location.AreaPin ? UserDetail.row.Location.AreaPin : "Not Filled"}
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>
                    <strong>City:</strong> {UserDetail.row.Location.City ? UserDetail.row.Location.City : 'Not Filled'}
                  </Typography>
                  <Typography>
                    <strong>Coords:</strong>{" "}
                    {UserDetail.row.Location.Coords.length != 0
                      ? UserDetail.row.Location.Coords
                      : "Not Filled"}
                  </Typography>
                </div>
                <div
                  style={{
                    flex: 2,
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>
                    <strong>Coloney:</strong>{" "}
                    {UserDetail.row.Location.Coords.length != 0
                      ? UserDetail.row.Location.Coloney
                      : "Not Filled"}
                  </Typography>
                </div>
              </div>
              <Typography variant="h5" marginTop={"15px"}>
                Education
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {UserDetail.row.Education.length > 0 ? UserDetail.row.Education.map((item, i) => (
                  <div key={i}>
                    <Typography variant="h6">{item.NatureOfStudies}</Typography>
                    <Typography>
                      {item.NatureOfStudies == "Degree"
                        ? item.Degree
                        : item.Diploma}
                    </Typography>
                    {(item.Degree || item.Diploma != "None") && (
                      <>
                        <Typography>{item.Institution}</Typography>
                        <Typography>
                          {item.City}, {item.Country}
                        </Typography>
                      </>
                    )}
                  </div>
                )): "Not Filled"}
              </div>
              <Typography variant="h5" marginTop={"15px"}>
                Employment
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography>
                  <strong>State Of Employment: </strong>
                  {UserDetail.row.Employeement ? UserDetail.row.Employeement.StateOfEmployment : "Not Filled"}
                </Typography>
                <Typography>
                  <strong>Nature Of Employment: </strong>
                  {UserDetail.row.Employeement ? UserDetail.row.Employeement.NatureOfEmployment : 'Not Filled'}
                </Typography>
                <Typography>
                  <strong>Profession: </strong>
                  {UserDetail.row.Employeement ? UserDetail.row.Employeement.Profession : "Not Filled"}
                </Typography>
              </div>
              <Typography variant="h5" marginTop={"15px"}>
                Profile Completion Progress
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography>
                  <strong>Current Stage:</strong>{" "}
                  {UserDetail.row.StageState.FirstBoot
                    ? UserDetail.row.StageState.Profile
                      ? UserDetail.row.StageState.PITs
                        ? UserDetail.row.StageState.Faces
                          ? UserDetail.row.StageState.Exams
                            ? "User Profile Complete"
                            : "Exams"
                          : "Biometric"
                        : "PIT"
                      : "Profile"
                    : "Cards"}
                </Typography>
              </div>
              <Typography marginTop={"15px"} variant="h5">
                RelationShip Questionnaire
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography>Final result: {UserDetail.row.PMT.FinalResult === 'BorderLine' ? UserDetail.row.PMT.RelationQuotient.Score.Remark : UserDetail.row.PMT.FinalResult }</Typography>
                <Typography>Mindset - {UserDetail.row.PMT.Mindset?.Judgement}</Typography>
                <Typography>Life Satisfaction - {UserDetail.row.PMT.LifeSatisfaction?.Judgement}</Typography>
                <Typography>Conscientiousness - {UserDetail.row.PMT.Conscientiousness?.Judgement}</Typography>
                <Typography>Attachment Style - {UserDetail.row.PMT.AttachmentStyle.Score?.Judgement}</Typography>
              </div>
              <Typography marginTop={"15px"} variant="h5">
                Matches
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {stackData.length === 0 ? <Typography>No matches</Typography> : stackData.map((i) => (
                  <Typography><Link to = {`/UserProfile/${i._id}`} state = {{ info : i._id}} target = '_blank'>{i._id}</Link></Typography>
                ))}
              </div>
            </CardContent>
          </Card>
        </Drawer>
      </Box>
    )
  );
};

export default UserInfo;
