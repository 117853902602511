import { Dialog,DialogContent, DialogTitle, Grid, List, ListItem, Paper, Typography, styled } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { toast } from "react-toastify";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link } from "react-router-dom"
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const columns = [
  { field: "_id", headerName: "User ID", width: 200, editable: false },
  { field: "uid", headerName: "User ID", width: 200, editable: false, renderCell: (params) => { return params.row.uid } },
  { field: "activity", headerName: "Activity", width: 200, editable: false, renderCell: (params) => { return params.row.activity } },
  { field: "Time", headerName: "Time", width: 200, editable: false, renderCell: (params) => { return params.row.time } },
  { field: "Date", headerName: "Date", width: 120, editable: false, renderCell: (params) => { return params.row.date }},
  { field: "Method", headerName: "Method", width: 120, editable: false, renderCell: (params) => { return params.row.method }},
  { field: "IP", headerName: "IP", width: 200, editable: false, renderCell: (params) => { return params.row.ip } },
  { field: "ConnectedW", headerName: "B User", width: 200, editable: false, renderCell: (params) => { return params.row.details ? params.row.details.uid : "NA"} },

]
const Logs = () => {
  const [logs, setLogs] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const logs = await axios.get(`${process.env.REACT_APP_SERVER}adm/logs`);
        setData(logs.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    fetchData();
  }, [])


  return loading || data == null ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      Loading...
    </div>
  ) : (
    <div style={{ width: "100vw", height: "100%", display: "flex", padding: '10px', flexDirection: 'column' }}>
        <DataGrid
            rows={data}
            columns={columns}
            getRowId={(row) => row._id}
            initialState={{
              sorting: {
                sortModel: [{ field: "date", sort: "desc" }],
              },
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[9]}
            checkboxSelection
            disableRowSelectionOnClick
            disableColumnFilter
            disableDensitySelector
            disableColumnSelector
            onSelectionChange={(e) => {
              setSelectedRows(e.selectionModel);
            }}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
    </div>
  );
};

export default Logs;
