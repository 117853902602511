import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Button } from "@mui/material";
import moment from "moment";
import Drawer from '@mui/material/Drawer';
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import UserInfo from "./UserInfo";

const calculateAge = (birthday) => {
  const startDate = new Date();
  const endDate = new Date(birthday);
  return Math.abs(moment.duration(endDate - startDate).years());
};

const columns = [
  { field: "email", headerName: "Email", width: 170, editable: false },
  { field: "phone", headerName: "Phone", width: 100, editable: false },
  { field: "fName", headerName: "First Name", width: 120, editable: false },
  { field: "lName", headerName: "Last Name", width: 120, editable: false },
  {
    field: "DOB",
    headerName: "DOB",
    width: 100,
    valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
  },
  {
    field: "Age",
    headerName: "Age",
    width: 50,
    valueGetter: (params) => {
      return calculateAge(new Date(params.row.DOB));
    },
  },
  { field: "gender", headerName: "Gender", width: 100, editable: false },
  {
    field: "matches",
    headerName: "Matches",
    width: 200,
    editable: false,
    valueGetter: (params) =>
      params.row.matches ? params.row.matches.length : 0,
  },
  {
    field: "BMI.Height",
    headerName: "Height(cms)",
    width: 100,
    editable: false,
    valueGetter: (params) => (params.row.BMI ? params.row.BMI.Height : null),
  },
  {
    field: "BMI.Weight",
    headerName: "Weight(Kgs)",
    width: 100,
    editable: false,
    valueGetter: (params) => (params.row.BMI ? params.row.BMI.Weight : null),
  },
  {
    field: "StageState.FirstBoot",
    headerName: "registed",
    width: 100,
    editable: false,
    valueGetter: (params) =>
      params.row.StageState ? params.row.StageState.FirstBoot : null,
  },
  {
    field: "StageState.Exams",
    headerName: "Charecter Tests",
    width: 100,
    editable: false,
    valueGetter: (params) =>
      params.row.StageState ? params.row.StageState.Exams : null,
  },
  {
    field: "PMT.Judgement",
    headerName: "Judgement",
    width: 100,
    editable: false,
    valueGetter: (params) =>
      params.row.PMT.FinalResult ? params.row.PMT.FinalResult : null,
  },
  {
    field: "PMT.LifeSatisfaction.Score",
    headerName: "LS-Result",
    idth: 100,
    editable: false,
    valueGetter: (params) =>
      params.row.PMT.LifeSatisfaction.Judgement
        ? params.row.PMT.LifeSatisfaction.Judgement
        : false,
  },
  // { field: 'PMT.LifeSatisfaction', headerName: 'LS-Result', width: 100, editable: false,  valueGetter: (params) => params.row.PMT.LifeSatisfaction.Score ? parseInt(params.row.PMT.LifeSatisfaction.Score.split("|")[1].trim().replace(/[^0-9]/g, ''), 10)>=24 ? "PASS" : "FAIL": false, },

  // { field: 'PMT.AttachmentStyle.Score', headerName: 'AttachmentStyle', width: 100, editable: false,  valueGetter: (params) => params.row.PMT.AttachmentStyle ? params.row.PMT.AttachmentStyle.Score : false, },
  {
    field: "PMT.AttachmentStyle",
    headerName: "AS-Result",
    width: 100,
    editable: false,
    valueGetter: (params) =>
      params.row.PMT.AttachmentStyle.Score
        ? params.row.PMT.AttachmentStyle.Score.Judgement
        : false,
  },

  // { field: 'PMT.Conscientiousness.Score', headerName: 'Conscientiousness', width: 100, editable: false,  valueGetter: (params) => params.row.PMT.Conscientiousness ? params.row.PMT.Conscientiousness.Score : false, },
  {
    field: "PMT.Conscientiousness",
    headerName: "Co-Result",
    width: 100,
    editable: false,
    valueGetter: (params) =>
      params.row.PMT.Conscientiousness.Judgement
        ? params.row.PMT.Conscientiousness.Judgement
        : false,
  },

  // { field: 'PMT.Mindset.Score', headerName: 'Mindset', width: 100, editable: false,  valueGetter: (params) => params.row.PMT.Mindset ? params.row.PMT.Mindset.Score : false, },
  {
    field: "PMT.Mindset",
    headerName: "MS-Result",
    width: 100,
    editable: false,
    valueGetter: (params) =>
      params.row.PMT.Mindset.Judgement
        ? params.row.PMT.Mindset.Judgement
        : false,
  },

  {
    field: "StageState.Profile",
    headerName: "Profile Form",
    width: 100,
    editable: false,
    valueGetter: (params) =>
      params.row.StageState ? params.row.StageState.Profile : null,
  },
  {
    field: "StageState.PITs",
    headerName: "Tags",
    width: 100,
    editable: false,
    valueGetter: (params) =>
      params.row.StageState ? params.row.StageState.PITs : null,
  },
  {
    field: "StageState.Faces",
    headerName: "Image",
    width: 100,
    editable: false,
    valueGetter: (params) =>
      params.row.StageState ? params.row.StageState.Faces : null,
  },
  {
    field: "createdAt",
    headerName: "Created at",
    width: 180,
    valueFormatter: (params) => new Date(params.value).toLocaleString(),
  },
];

function UserExplorer() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [details, setDetails] = useState(null);
  const [open, setOpen] = useState(false);
    //  useEffect(() => {
    //   console.log(selectedRows);
    //   }, [selectedRows]);

    useEffect(() => {
      const fetchMatches = async () => {
        setLoading(true);
        try {
          const matches = await axios.get(
            `${process.env.REACT_APP_SERVER}adm/matches`
          );
          const data = matches.data.data;
          const filtered = data.filter(
            (entry) => entry.Matches.length > 0
          );
          if (Array.isArray(filtered) && filtered.length > 0) {
            const flattenedData = filtered.map((item) => ({
              ...item.user.Constants,
              _id: item.user._id,
              matches: item.Matches,
              ...item.user,
            }));
            console.log(flattenedData)
            setData(flattenedData);
          }
          setLoading(false);
        } catch (error) {
          console.log(error);
          // toast.error(error.response.data.message);
          setLoading(false);
        }
      };
      fetchMatches();
    }, [page]);
    const handleDrawerClose = () => {
      setOpen(false);
    }
    const handleUser = (props) => {
      setDetails(props)
      setOpen(true)
    }
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
          fontSize: "2em",
        }}
      >
        Loading...
      </div>
    );
  }
  async function copyTableToClipboard() {
    // Define a helper function to recursively convert nested objects and arrays to strings
    const flattenObject = (obj, prefix = "") => {
      return Object.keys(obj).reduce((accumulator, key) => {
        const newPrefix = prefix ? `${prefix}.${key}` : key;
        if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
          // Recursively process nested objects
          return accumulator.concat(flattenObject(obj[key], newPrefix));
        } else if (Array.isArray(obj[key])) {
          // Process arrays
          return accumulator.concat(
            obj[key].map((item, index) => ({
              key: `${newPrefix}[${index}]`,
              value: item,
            }))
          );
        } else {
          // Handle other types of values
          return accumulator.concat([{ key: newPrefix, value: obj[key] }]);
        }
      }, []);
    };

    // Convert the table data to CSV
    // let csvData = data.map(row => {
    //   const flatRow = flattenObject(row);
    //   return flatRow.map(field => `"${field.value}"`).join(',');
    // }).join('\n');

    // // Copy the CSV data to the clipboard
    // try {
    //   // await navigator.clipboard.writeText(csvData);
    //   alert('Table copied to clipboard');
    // } catch (err) {
    //   console.error('Could not copy data: ', err);
    // }
  }

  return (
    <Box sx={{ height: "95%", m: "auto" }}>
      {/* <Button
   onClick={() => {
    copyTableToClipboard()
   }}
  >
   Copy Emails
  </Button> */}
      <DataGrid
        rows={data}
        columns={columns}
        getRowId={(row) => row._id}
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[9]}
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnFilter
        disableDensitySelector
        disableColumnSelector
        onSelectionChange={(e) => {
          setSelectedRows(e.selectionModel);
        }}
        onRowClick={(props) => {
          handleUser(props)
        }}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
      <UserInfo open = {open} UserDetail = {details} handleDrawerClose={handleDrawerClose} />
    </Box>
  );
}

export default UserExplorer;
