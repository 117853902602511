import { useState } from 'react';
// , useRef, useCallback
import axios from 'axios';
import { Link } from 'react-router-dom';
import {  TextField, Box, Button, Card, Grid, CardActions, CardContent, CardMedia, Typography } from '@mui/material';

const Register = () => {
  const [userData, setUserData] = useState({ email: '', phone: '', username: '', password: ''});

  const { email, phone, username, password} = userData;

  const onChange = e => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };


  const register = async () => {
    const newUser = {
        email,
        phone,
        username,
        password,
    };

    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };

      const body = JSON.stringify(newUser);
      await axios.post(`${process.env.REACT_APP_SERVER}adm/signup`, body, config);
      window.location.href="/"

    } catch (err) {
      console.error(err.response.data);
    }
  };

  const buttonStyle={
    display: 'flex',
    width: '343px',
    padding: '16px 32px',
    marginTop: '3vh',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
    borderRadius: '100px',
    backgroundColor: '#231b7e',
    color:'white'
  }


    return (
      <div>
      <Grid
        container
        justifyContent="center"
        alignItems="top"
        style={{ minHeight: '100vh', marginTop:'10vh' }}
      >
        <Box>
       <Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 345 }}>
       <CardMedia
          component="img"
          alt="Matchworthee.com"
          style={{ height: "80px", objectFit: "contain" }}
          image="./BIY.png"
        />
        <CardMedia
          component="img"
          alt="Matchworthee.com"
          style={{ margin: "30px 0px 30px 0", height: "50px", objectFit: "contain" }}
          image="./BLB.png"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" align="center" component="div">
            Register
          </Typography>
          <Typography gutterBottom variant="p" style={{textAlign:'right', color:'#231b7e', textDecoration:'none'}} component={Link} to={'/'}>
          login
        </Typography>
        </CardContent>
        <CardActions>
        <Grid container justifyContent="center" alignItems="center">
            <TextField  label="Email" fullWidth style={{marginTop:'1vh'}}  sx={{"& .MuiOutlinedInput-root.Mui-focused": {"& fieldset": {borderColor: '#231b7e',},},"& label.Mui-focused": {color: '#231b7e',},}} variant="outlined" name="email" value={email} onChange={e => onChange(e)} placeholder="Email" />
            <TextField  label="Phone" fullWidth style={{marginTop:'1vh'}} sx={{"& .MuiOutlinedInput-root.Mui-focused": {"& fieldset": {borderColor: '#231b7e',},},"& label.Mui-focused": {color: '#231b7e',},}} variant="outlined" name="phone" value={phone} onChange={e => onChange(e)} placeholder="Phone" />
            <TextField label="Username" fullWidth style={{marginTop:'1vh'}} sx={{"& .MuiOutlinedInput-root.Mui-focused": {"& fieldset": {borderColor: '#231b7e',},},"& label.Mui-focused": {color: '#231b7e',},}} variant="outlined" name="username" value={username} onChange={e => onChange(e)} placeholder="Username" />
            <TextField label="Password" fullWidth style={{marginTop:'1vh', marginBottom:'3vh'}} sx={{"& .MuiOutlinedInput-root.Mui-focused": {"& fieldset": {borderColor: '#231b7e',},},"& label.Mui-focused": {color: '#231b7e',},}} type="password" name="password" value={password} onChange={e => onChange(e)} placeholder="Password" />
      
            <Button  style={buttonStyle} variant="outlined" onClick={register}>Register</Button>
        </Grid>
        </CardActions>
      </Card>
      </Box>
      </Grid>
      </div>
    );
};

export default Register;