import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import Card from "@mui/material/Card";
import { useEffect } from "react";
import {
  Avatar,
  AvatarGroup,
  Box,
  CardContent,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import FaceIcon from "@mui/icons-material/Face";
import TransgenderIcon from "@mui/icons-material/Transgender";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
const User = () => {
  const [loading, setLoading] = useState(false);
  const [stackData, setStackData] = useState([]);
  const [UserDetail, setUserDetail] = useState(null);
  const location = useLocation();
  const [info, setInfo] = useState(null);
  useEffect(() => {
    let id = "";
    if (location.state == null) {
      id =
        location.pathname.split("/")[location.pathname.split("/").length - 1];
    } else {
      id = location.state;
    }
    setInfo(id);
    fetchData(id);
    fetchMatches(id);
  }, []);
  const fetchData = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}m/${id}`
      );
      setUserDetail(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchMatches = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}m/ReturnStack/${id}`
      );
      await setStackData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setStackData([]);
    }
  };
  function calculateBMI(Height, Weight) {
    return Math.round((Weight / Math.pow(Height / 100, 2)) * 100) / 100;
  }
  function calculateAge(birthDate) {
    let birth = new Date(birthDate);
    let now = new Date();
    let age = now.getFullYear() - birth.getFullYear();
    let monthDifference = now.getMonth() - birth.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && now.getDate() < birth.getDate())
    ) {
      age--;
    }
    return age;
  }

  return (
    UserDetail && (
      <div style={{ maxWidth: '100vw', display: 'flex', justifyContent: 'center'}}>
        <Box sx={{ maxWidth: "500px" }}>
          <Card
            sx={{
              "&.MuiCard-root": {
                overflow: "initial",
              },
            }}
            style={{
              boxShadow: "none",
              // marginBottom: '5vh',
              display: "flex",
              alignItems: "center",
              alignSelf: "center",
              flexDirection: "column",
              minWidth: "300px",
              // marginTop: "20px",
            }}
          >
            <AvatarGroup>
              <Avatar
                src={loading ? null : UserDetail.face}
                alt={UserDetail.Constants.username}
                style={{ width: "150px", height: "150px" }}
              />
            </AvatarGroup>
            <CardContent style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography fontSize={24}>
                  {UserDetail.Constants.fName
                    ? UserDetail.Constants.fName
                    : "Not Filled"}{" "}
                  {UserDetail.Constants.lName
                    ? UserDetail.Constants.lName
                    : "Not Filled"}
                </Typography>
                <Typography fontSize={24}>
                  {calculateAge(UserDetail.Constants.DOB)}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography>
                  <strong>Profile Created on:</strong>{" "}
                  {new Date(UserDetail.createdAt).toLocaleDateString()}
                </Typography>
                <Typography>
                  <strong>Last Update:</strong>{" "}
                  {new Date(UserDetail.updatedAt).toLocaleDateString()}
                </Typography>
              </div>
              <Typography variant="h5" marginTop={"15px"}>
                Personal Info
              </Typography>
              <div style={{}}>
                <Typography display={"flex"} alignItems={"center"} gap={2}>
                  <EmailIcon /> {UserDetail.Constants.email}
                </Typography>
                <Typography display={"flex"} alignItems={"center"} gap={2}>
                  <PhoneIcon /> {UserDetail.Constants.phone}
                </Typography>
                <Typography display={"flex"} alignItems={"center"} gap={2}>
                  {UserDetail.Constants.gender === "male" ? (
                    <MaleIcon />
                  ) : UserDetail.Constants.gender === "female" ? (
                    <FemaleIcon />
                  ) : (
                    <TransgenderIcon />
                  )}{" "}
                  {UserDetail.Constants.gender}
                </Typography>
              </div>

              <Typography variant="h5" marginTop={"15px"}>
                BMI
              </Typography>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography>
                    <strong>AtrScore:</strong>{" "}
                    {UserDetail.AtrScore ? UserDetail.AtrScore : "Not Filled"}
                  </Typography>
                  <Typography>
                    <strong>Height:</strong>{" "}
                    {UserDetail.BMI ? UserDetail.BMI.Height : "Not Filled"}
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>
                    <strong>Weight:</strong>{" "}
                    {UserDetail.BMI ? UserDetail.BMI.Weight : "Not Filled"}
                  </Typography>
                  <Typography>
                    <strong>BMI:</strong>{" "}
                    {UserDetail.BMI &&
                      calculateBMI(
                        UserDetail.BMI.Height,
                        UserDetail.BMI.Weight
                      )}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>
                    <strong>CAS:</strong> {UserDetail.CAS ? UserDetail.CAS : 'Not Filled'}
                  </Typography>
                  <Typography>
                    <strong>SES:</strong>{" "}
                    {UserDetail.SES ? UserDetail.SES : 'Not Filled'}
                  </Typography>
                </div>
              </div>
              <Typography variant="h5" marginTop={"15px"}>
                Location
              </Typography>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography>
                    <strong>Area:</strong>{" "}
                    {UserDetail.Location.Area
                      ? UserDetail.Location.Area
                      : "Not Filled"}
                  </Typography>
                  <Typography>
                    <strong>Area Pin:</strong>{" "}
                    {UserDetail.Location.AreaPin
                      ? UserDetail.Location.AreaPin
                      : "Not Filled"}
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>
                    <strong>City:</strong>{" "}
                    {UserDetail.Location.City
                      ? UserDetail.Location.City
                      : "Not Filled"}
                  </Typography>
                  <Typography>
                    <strong>Coords:</strong>{" "}
                    {UserDetail.Location.Coords.length != 0
                      ? UserDetail.Location.Coords
                      : "Not Filled"}
                  </Typography>
                </div>
                <div
                  style={{
                    flex: 2,
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>
                    <strong>Coloney:</strong>{" "}
                    {UserDetail.Location.Coords.length != 0
                      ? UserDetail.Location.Coloney
                      : "Not Filled"}
                  </Typography>
                </div>
              </div>
              <Typography variant="h5" marginTop={"15px"}>
                Education
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {UserDetail.Education.length > 0
                  ? UserDetail.Education.map((item, i) => (
                      <div key={i}>
                        <Typography variant="h6">
                          {item.NatureOfStudies}
                        </Typography>
                        <Typography>
                          {item.NatureOfStudies == "Degree"
                            ? item.Degree
                            : item.Diploma}
                        </Typography>
                        {(item.Degree || item.Diploma != "None") && (
                          <>
                            <Typography>{item.Institution}</Typography>
                            <Typography>
                              {item.City}, {item.Country}
                            </Typography>
                          </>
                        )}
                      </div>
                    ))
                  : "Not Filled"}
              </div>
              <Typography variant="h5" marginTop={"15px"}>
                Employment
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography>
                  <strong>State Of Employment: </strong>
                  {UserDetail.Employeement
                    ? UserDetail.Employeement.StateOfEmployment
                    : "Not Filled"}
                </Typography>
                <Typography>
                  <strong>Nature Of Employment: </strong>
                  {UserDetail.Employeement
                    ? UserDetail.Employeement.NatureOfEmployment
                    : "Not Filled"}
                </Typography>
                <Typography>
                  <strong>Profession: </strong>
                  {UserDetail.Employeement
                    ? UserDetail.Employeement.Profession
                    : "Not Filled"}
                </Typography>
              </div>
              <Typography variant="h5" marginTop={"15px"}>
                Profile Completion Progress
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography>
                  <strong>Current Stage:</strong>{" "}
                  {UserDetail.StageState.FirstBoot
                    ? UserDetail.StageState.Profile
                      ? UserDetail.StageState.PITs
                        ? UserDetail.StageState.Faces
                          ? UserDetail.StageState.Exams
                            ? "User Profile Complete"
                            : "Exams"
                          : "Biometric"
                        : "PIT"
                      : "Profile"
                    : "Cards"}
                </Typography>
              </div>
              <Typography marginTop={"15px"} variant="h5">
                RelationShip Questionnaire
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography>
                  Final result:{" "}
                  {UserDetail.PMT.FinalResult === "BorderLine"
                    ? UserDetail.PMT.RelationQuotient.Score.Remark
                    : UserDetail.PMT.FinalResult}
                </Typography>
                <Typography>Mindset - {UserDetail.PMT.Mindset?.Judgement}</Typography>
                <Typography>Life Satisfaction - {UserDetail.PMT.LifeSatisfaction?.Judgement}</Typography>
                <Typography>Conscientiousness - {UserDetail.PMT.Conscientiousness?.Judgement}</Typography>
                <Typography>Attachment Style - {UserDetail.PMT.AttachmentStyle.Score?.Judgement}</Typography>
                {UserDetail.PMT.RelationQuotient.Status && <Typography>RelationQuotient - {UserDetail.PMT.RelationQuotient.Score?.Value}</Typography>}
              </div>
              <Typography marginTop={"15px"} variant="h5">
                Matches
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {stackData.length === 0 ? <Typography>No matches</Typography> : stackData.map((i) => (
                  <Typography><Link to = {`/UserProfile/${i._id}`} state = {{ info : i._id}} target = '_blank'>{i._id}</Link></Typography>
                ))}
              </div>
            </CardContent>
          </Card>
        </Box>
      </div>
    )
  );
};

export default User;
