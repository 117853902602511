import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  styled,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import TuneIcon from "@mui/icons-material/Tune";
import moment from "moment";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import UserInfo from "./UserInfo";
import { PieChart } from "@mui/x-charts/PieChart";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const columns = [
  { field: "email", headerName: "Email", width: 170, editable: false },
  { field: "phone", headerName: "Phone", width: 100, editable: false },
  { field: "fName", headerName: "First Name", width: 120, editable: false },
  { field: "lName", headerName: "Last Name", width: 120, editable: false },
  {
    field: "DOB",
    headerName: "DOB",
    width: 100,
    valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
  },
  {
    field: "Age",
    headerName: "Age",
    width: 50,
    valueGetter: (params) => {
      return calculateAge(new Date(params.row.DOB));
    },
  },
  { field: "gender", headerName: "Gender", width: 100, editable: false },
  {
    field: "BMI.Height",
    headerName: "Height(cms)",
    width: 100,
    editable: false,
    valueGetter: (params) => (params.row.BMI ? params.row.BMI.Height : null),
  },
  {
    field: "BMI.Weight",
    headerName: "Weight(Kgs)",
    width: 100,
    editable: false,
    valueGetter: (params) => (params.row.BMI ? params.row.BMI.Weight : null),
  },
  {
    field: "StageState.FirstBoot",
    headerName: "registed",
    width: 100,
    editable: false,
    valueGetter: (params) =>
      params.row.StageState ? params.row.StageState.FirstBoot : null,
  },
  {
    field: "StageState.Exams",
    headerName: "Charecter Tests",
    width: 100,
    editable: false,
    valueGetter: (params) =>
      params.row.StageState ? params.row.StageState.Exams : null,
  },
  {
    field: "PMT.Judgement",
    headerName: "Judgement",
    width: 100,
    editable: false,
    valueGetter: (params) =>
      params.row.PMT.FinalResult ? params.row.PMT.FinalResult : null,
  },
  {
    field: "PMT.LifeSatisfaction.Score",
    headerName: "LS-Result",
    idth: 100,
    editable: false,
    valueGetter: (params) =>
      params.row.PMT.LifeSatisfaction.Judgement
        ? params.row.PMT.LifeSatisfaction.Judgement
        : false,
  },
  // { field: 'PMT.LifeSatisfaction', headerName: 'LS-Result', width: 100, editable: false,  valueGetter: (params) => params.row.PMT.LifeSatisfaction.Score ? parseInt(params.row.PMT.LifeSatisfaction.Score.split("|")[1].trim().replace(/[^0-9]/g, ''), 10)>=24 ? "PASS" : "FAIL": false, },

  // { field: 'PMT.AttachmentStyle.Score', headerName: 'AttachmentStyle', width: 100, editable: false,  valueGetter: (params) => params.row.PMT.AttachmentStyle ? params.row.PMT.AttachmentStyle.Score : false, },
  {
    field: "PMT.AttachmentStyle",
    headerName: "AS-Result",
    width: 100,
    editable: false,
    valueGetter: (params) =>
      params.row.PMT.AttachmentStyle.Score
        ? params.row.PMT.AttachmentStyle.Score.Judgement
        : false,
  },

  // { field: 'PMT.Conscientiousness.Score', headerName: 'Conscientiousness', width: 100, editable: false,  valueGetter: (params) => params.row.PMT.Conscientiousness ? params.row.PMT.Conscientiousness.Score : false, },
  {
    field: "PMT.Conscientiousness",
    headerName: "Co-Result",
    width: 100,
    editable: false,
    valueGetter: (params) =>
      params.row.PMT.Conscientiousness.Judgement
        ? params.row.PMT.Conscientiousness.Judgement
        : false,
  },

  // { field: 'PMT.Mindset.Score', headerName: 'Mindset', width: 100, editable: false,  valueGetter: (params) => params.row.PMT.Mindset ? params.row.PMT.Mindset.Score : false, },
  {
    field: "PMT.Mindset",
    headerName: "MS-Result",
    width: 100,
    editable: false,
    valueGetter: (params) =>
      params.row.PMT.Mindset.Judgement
        ? params.row.PMT.Mindset.Judgement
        : false,
  },

  {
    field: "StageState.Profile",
    headerName: "Profile Form",
    width: 100,
    editable: false,
    valueGetter: (params) =>
      params.row.StageState ? params.row.StageState.Profile : null,
  },
  {
    field: "StageState.PITs",
    headerName: "Tags",
    width: 100,
    editable: false,
    valueGetter: (params) =>
      params.row.StageState ? params.row.StageState.PITs : null,
  },
  {
    field: "StageState.Faces",
    headerName: "Image",
    width: 100,
    editable: false,
    valueGetter: (params) =>
      params.row.StageState ? params.row.StageState.Faces : null,
  },
  {
    field: "createdAt",
    headerName: "Created at",
    width: 180,
    valueFormatter: (params) => new Date(params.value).toLocaleString(),
  },
];
const calculateAge = (birthday) => {
  const startDate = new Date();
  const endDate = new Date(birthday);
  return Math.abs(moment.duration(endDate - startDate).years());
};

const Reports = () => {
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState(null);
  const [genderData, setGenderData] = useState(null);
  const [details, setDetails] = useState(null);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState({
    gen: null,
    age: null,
    qual: null,
    marr: null,
    orien: null,
    edu: null,
    occ: null,
    prof: null,
    sta: null,
    ses: null,
    cas: null,
  });
  const [filteredData, setFilteredData] = useState([]);
  const [empForm, setEmpForm] = useState(null);

  useEffect(() => {
    const fetchEmpForm = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}m/PProfile`
      );
      const data = response.data;
      setEmpForm(data);
      setLoading(false);
    };
    setLoading(true);
    fetchEmpForm();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER}adm/userSuperFetch`
        );
        if (Array.isArray(response.data) && response.data.length > 0) {
          const flattenedData = response.data.map((user) => ({
            ...user.Constants,
            _id: user._id,
            ...user.StageState,
            ...user,
            age: calculateAge(user.Constants.DOB),
          }));
          setData(flattenedData);
          setFilteredData(flattenedData);
          const maleCount = flattenedData ? flattenedData.filter(
            (user) => user.gender === "male"
          ).length : data && data.filter(
            (user) => user.gender === "male"
          ).length;
          const femaleCount = flattenedData ? flattenedData.filter(
            (user) => user.gender === "female"
          ).length: data && data.filter(
            (user) => user.gender === "female"
          ).length;
          const otherCount = flattenedData ? flattenedData.filter(
            (user) => user.gender !== "male" && user.gender !== "female"
          ).length: data && data.filter(
            (user) => user.gender !== "male" && user.gender !== "female"
          ).length;
          setGenderData([
            { id: 0, value: maleCount, label: "Male" },
            { id: 1, value: femaleCount, label: "Female" },
            { id: 2, value: otherCount, label: "Other" },
          ]);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    setLoading(true);
    fetchData();
  }, []);
  const handleChange = (e) => {
    setLoading(true);
    const fil = { ...filter, [e.target.name]: e.target.value };
    // console.log(fil);
    setFilter(fil);
    // console.log(filteredData);
    setLoading(false);
  };

  const combineFilters =
    (...filters) =>
    (item) => {
      return filters.reduce((acc, filter) => acc && filter(item), true);
    };
  useEffect(() => {
    //   console.log(combineFilters(ageFilter));
    setLoading(true);
    const filteredArr =
      data &&
      data.filter(
        combineFilters(
          genderFilter,
          ageFilter,
          qualFilter,
          maritalFilter,
          orientationFilter,
          educationFilter,
          occupationFilter,
          professionFilter,
          stageFilter,
          sesFilter,
          casFilter
        )
      );
    setFilteredData(filteredArr);
    const maleCount = filteredArr ? filteredArr.filter(
      (user) => user.gender === "male"
    ).length : data && data.filter(
      (user) => user.gender === "male"
    ).length;
    const femaleCount = filteredArr ? filteredArr.filter(
      (user) => user.gender === "female"
    ).length: data && data.filter(
      (user) => user.gender === "female"
    ).length;
    const otherCount = filteredArr ? filteredArr.filter(
      (user) => user.gender !== "male" && user.gender !== "female"
    ).length: data && data.filter(
      (user) => user.gender !== "male" && user.gender !== "female"
    ).length;
    setGenderData([
      { id: 0, value: maleCount, label: "Male" },
      { id: 1, value: femaleCount, label: "Female" },
      { id: 2, value: otherCount, label: "Other" },
    ]);
    setLoading(false);
  }, [filter]);

  const ageFilter = (item) => {
    if (filter["age"] === null) return true;
    if (filter["age"] === "custom") {
    }
    const [lowerbound, upperbound] = filter["age"].split("-");
    return item.age > parseInt(lowerbound) && item.age <= parseInt(upperbound)
      ? true
      : false;
  };
  const genderFilter = (item) => {
    return filter["gen"] === null ? true : item.gender === filter["gen"];
  };
  const qualFilter = (item) => {
    return filter["qual"] === null
      ? true
      : item.PMT.FinalResult === filter["qual"];
  };
  const maritalFilter = (item) => {
    return filter["marr"] === null
      ? true
      : item.Practs && item.Practs.MaritalStatus === filter["marr"];
  };
  const orientationFilter = (item) => {
    return filter["orien"] === null
      ? true
      : item.Practs && item.Practs.SexualOrientation === filter["orien"];
  };
  const occupationFilter = (item) => {
    return filter["occ"] === null
      ? true
      : item.Practs && item.Employeement.StateOfEmployment === filter["occ"];
  };
  const educationFilter = (item) => {
    return filter["edu"] === null
      ? true
      : item.Education &&
          item.Education.filter((edu) => edu.NatureOfStudies === "Degree")
            .length !== 0 &&
          item.Education.filter((edu) => edu.NatureOfStudies === "Degree")[0]
            .Degree &&
          item.Education.filter(
            (edu) => edu.NatureOfStudies === "Degree"
          )[0].Degree.toLowerCase() === filter["edu"].toLowerCase();
  };
  const professionFilter = (item) => {
    return filter["prof"] === null
      ? true
      : item.Employeement && item.Employeement.Profession === filter["prof"];
  };

  const stageFilter = (item) => {
    return filter["sta"] === null
      ? true
      : filter["sta"] === "Cards"
      ? !item.FirstBoot
      : !item[filter["sta"]];
  };
  const sesFilter = (item) => {
    return filter["ses"] === null ? true : item.SES === filter["ses"];
  };
  const casFilter = (item) => {
    return filter["cas"] === null
      ? true
      : Math.floor(item.CAS) === filter["cas"];
  };

  const handleUser = (props) => {
    setDetails(props)
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false);
  }

  return loading || !data ? (
    <div
      style={{
        width: "100vw",
        height: "calc(100vh - 80px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: "bold",
        fontSize: "24px",
      }}
    >
      Loading...
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div style={{ marginTop: "5vh" }}>
        <Typography alignItems="center" display={"flex"} variant="h4" gap={3}>
          <TuneIcon style={{ width: "60px", height: "60px" }} /> Filters
        </Typography>
      </div>
      <div>
        <FormControl sx={{ m: 1, minWidth: 80 }}>
          <InputLabel id="GenderFilter">Gender</InputLabel>
          <Select
            labelId="GenderFilter"
            id="genderFilter"
            onChange={handleChange}
            autoWidth
            value={`${filter.gen}`}
            name="gen"
            label="Gender"
          >
            <MenuItem value={null}>
              <em>None</em>
            </MenuItem>
            <MenuItem value={"male"}>Male</MenuItem>
            <MenuItem value={"female"}>Female</MenuItem>
            <MenuItem value={"other"}>Other</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 80 }}>
          <InputLabel id="AgeFilter">Age</InputLabel>
          <Select
            labelId="AgeFilter"
            id="ageFilter"
            onChange={handleChange}
            autoWidth
            value={`${filter.age}`}
            name="age"
            label="Age"
          >
            <MenuItem value={null}>
              <em>None</em>
            </MenuItem>
            <MenuItem value={"20-30"}>20-30</MenuItem>
            <MenuItem value={"26-35"}>26-35</MenuItem>
            <MenuItem value={"31-40"}>30-40</MenuItem>
            <MenuItem value={"36-45"}>36-45</MenuItem>
            <MenuItem value={"40-50"}>40-50</MenuItem>
            <MenuItem value={"46-55"}>46-55</MenuItem>
            <MenuItem value={"50-60"}>50-60</MenuItem>
            <MenuItem value={"56-65"}>56-65</MenuItem>
            <MenuItem value={"60-70"}>60-70</MenuItem>
            <MenuItem value={"66-75"}>66-75</MenuItem>
            <MenuItem value={"20-25"}>20-25</MenuItem>
            <MenuItem value={"26-30"}>26-30</MenuItem>
            <MenuItem value={"31-35"}>31-35</MenuItem>
            <MenuItem value={"36-40"}>36-40</MenuItem>
            <MenuItem value={"41-45"}>41-45</MenuItem>
            <MenuItem value={"46-50"}>46-50</MenuItem>
            <MenuItem value={"51-55"}>51-55</MenuItem>
            <MenuItem value={"56-60"}>56-60</MenuItem>
            <MenuItem value={"61-65"}>61-65</MenuItem>
            <MenuItem value={"66-70"}>66-70</MenuItem>
            <MenuItem value={"70-100"}>70 Above</MenuItem>
            {/* <MenuItem inputMode="numeric" component="input" /> */}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 160 }}>
          <InputLabel id="QualificationFilter">Qualification</InputLabel>
          <Select
            labelId="QualificationFilter"
            id="ageFilter"
            onChange={handleChange}
            autoWidth
            value={`${filter.qual}`}
            name="qual"
            label="Qualification"
          >
            <MenuItem value={null}>
              <em>None</em>
            </MenuItem>
            <MenuItem value={"Pass"}>Pass</MenuItem>
            <MenuItem value={"Fail"}>Fail</MenuItem>
            <MenuItem value={"BorderLine"}>BorderLine</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 160 }}>
          <InputLabel id="MaritalFilter">Marital Status</InputLabel>
          <Select
            labelId="MaritalFilter"
            id="maritalFilter"
            onChange={handleChange}
            autoWidth
            value={`${filter.marr}`}
            name="marr"
            label="Marital Status"
          >
            <MenuItem value={null}>
              <em>None</em>
            </MenuItem>
            <MenuItem value={"Single"}>Single</MenuItem>
            <MenuItem value={"Married"}>Married</MenuItem>
            <MenuItem value={"Divorced"}>Divorced</MenuItem>
            <MenuItem value={"Widow"}>Widow</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 160 }}>
          <InputLabel id="OrientationFilter">Sexual Orientation</InputLabel>
          <Select
            labelId="OrientationFilter"
            id="orientationFilter"
            onChange={handleChange}
            autoWidth
            value={`${filter.orien}`}
            name="orien"
            label="Sexual Orientation"
          >
            <MenuItem value={null}>
              <em>None</em>
            </MenuItem>
            <MenuItem value={"straight"}>Straight</MenuItem>
            <MenuItem value={"Gay"}>Gay</MenuItem>
            <MenuItem value={"Lesbian"}>Lesbian</MenuItem>
            <MenuItem value={"biSexual"}>Bi-Sexual</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 160 }}>
          <InputLabel id="EducationFilter">Education</InputLabel>
          <Select
            labelId="EducationFilter"
            id="educationFilter"
            onChange={handleChange}
            autoWidth
            value={`${filter.edu}`}
            name="edu"
            label="Education"
          >
            <MenuItem value={null}>
              <em>None</em>
            </MenuItem>
            <MenuItem value={"Doctrate"}>Doctrate</MenuItem>
            <MenuItem value={"Post-doctrate"}>Post-doctrate</MenuItem>
            <MenuItem value={"Fellowship"}>Fellowship</MenuItem>
            <MenuItem value={"MS"}>MS</MenuItem>
            <MenuItem value={"MA"}>MA</MenuItem>
            <MenuItem value={"MD"}>MD</MenuItem>
            <MenuItem value={"MBA"}>MBA</MenuItem>
            <MenuItem value={"MSc"}>MSc</MenuItem>
            <MenuItem value={"MTech"}>MTech</MenuItem>
            <MenuItem value={"MPharma"}>MPharma</MenuItem>
            <MenuItem value={"Other"}>Other</MenuItem>
            <MenuItem value={"BA/BCom/BSc/BBMA"}>BA/BCom/BSc/BBMA</MenuItem>
            <MenuItem value={"BTech"}>BTech</MenuItem>
            <MenuItem value={"MBBS"}>MBBS</MenuItem>
            <MenuItem value={"Computer Science"}>Computer Science</MenuItem>
            <MenuItem value={"BPharma"}>BPharma</MenuItem>
            <MenuItem value={"Others"}>Others</MenuItem>
            <MenuItem value={"12th Standard"}>12th Standard</MenuItem>
            <MenuItem value={"10th Standard"}>10th Standard</MenuItem>
            <MenuItem value={"Up to 9th Standard"}>Up to 9th Standard</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 160 }}>
          <InputLabel id="OccupationFilter">Working Status</InputLabel>
          <Select
            labelId="OccupationFilter"
            id="occupationFilter"
            onChange={handleChange}
            autoWidth
            value={`${filter.occ}`}
            name="occ"
            label="Working Status"
          >
            <MenuItem value={null}>
              <em>None</em>
            </MenuItem>
            <MenuItem value={"Working"}>Working</MenuItem>
            <MenuItem value={"Not Working"}>Not Working</MenuItem>
            <MenuItem value={"Never Worked"}>Never Worked</MenuItem>
            <MenuItem value={"Retired"}>Retired</MenuItem>
            <MenuItem value={"Student"}>Student</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 160 }}>
          <InputLabel id="ProfessionFilter">Profession</InputLabel>
          <Select
            labelId="ProfessionFilter"
            id="professionFilter"
            onChange={handleChange}
            autoWidth
            value={`${filter.prof}`}
            name="prof"
            label="Profession"
          >
            <MenuItem value={null}>
              <em>None</em>
            </MenuItem>
            {empForm &&
              empForm.Profession.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 160 }}>
          <InputLabel id="stageFilter">Current Stage</InputLabel>
          <Select
            labelId="stageFilter"
            id="stageFilter"
            onChange={handleChange}
            autoWidth
            value={`${filter.sta}`}
            name="sta"
            label="Current Stage"
          >
            <MenuItem value={null}>
              <em>None</em>
            </MenuItem>
            <MenuItem value={"Cards"}>Cards</MenuItem>
            <MenuItem value={"Profile"}>Profile</MenuItem>
            <MenuItem value={"PITs"}>PITs</MenuItem>
            <MenuItem value={"Faces"}>Faces</MenuItem>
            <MenuItem value={"Exams"}>Exams</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 160 }}>
          <InputLabel id="SESFilter">SES Score</InputLabel>
          <Select
            labelId="SESFilter"
            id="SESFilter"
            onChange={handleChange}
            autoWidth
            value={`${filter.ses}`}
            name="ses"
            label="SES Score"
          >
            <MenuItem value={null}>
              <em>None</em>
            </MenuItem>
            <MenuItem value={"A"}>A</MenuItem>
            <MenuItem value={"B"}>B</MenuItem>
            <MenuItem value={"C"}>C</MenuItem>
            <MenuItem value={"D"}>D</MenuItem>
            <MenuItem value={"E"}>E</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 160 }}>
          <InputLabel id="CASFilter">CAS Score</InputLabel>
          <Select
            labelId="CASFilter"
            id="CASFilter"
            onChange={handleChange}
            autoWidth
            value={`${filter.cas}`}
            name="cas"
            label="CAS Score"
          >
            <MenuItem value={null}>
              <em>None</em>
            </MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={0}>0</MenuItem>
          </Select>
        </FormControl>
      </div>
      <Typography variant="h4">Charts</Typography>
      <div style={{ 
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        flexDirection: "column",
        width: "100%",
        }}>
        <Grid>
          <Item
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              // width: '20%',
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant="h5">Gender Distribution</Typography>
            <div style={{ height: "100%" }}>
              <PieChart
                series={[
                  {
                    data: genderData && genderData.length > 0 ? genderData : [],
                    innerRadius: 40,
                    outerRadius: 130,
                    paddingAngle: 5,
                    cornerRadius: 5,
                    startAngle: -90,
                    endAngle: 270,
                    cx: 150,
                    cy: 150,
                  },
                ]}
                width={400}
                height={300}
              />
            </div>
          </Item>
        </Grid>
      </div>
      <div>
        <Typography variant="h4">User List</Typography>
        <Typography>Total: {filteredData && filteredData.length}</Typography>

        {filteredData && filteredData.length !== 0 ? (
          <DataGrid
            rows={filteredData}
            columns={columns}
            getRowId={(row) => row._id}
            initialState={{
              sorting: {
                sortModel: [{ field: "createdAt", sort: "desc" }],
              },
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[9]}
            checkboxSelection
            disableRowSelectionOnClick
            disableColumnFilter
            disableDensitySelector
            disableColumnSelector
            onRowClick={(props) => {
              handleUser(props)
            }}
            onSelectionChange={(e) => {
              setSelectedRows(e.selectionModel);
            }}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        ) : (
          "No Such Users"
        )}
      </div>
      <UserInfo open = {open} UserDetail = {details} handleDrawerClose={handleDrawerClose} />

    </div>
  );
};

export default Reports;
