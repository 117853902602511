import React from 'react';
import {Button } from '@mui/material';

// import { Link, Route, Navigate } from 'react-router-dom';

function WaitAdm() {
  const handleLogout = () => {
    localStorage.removeItem('userToken');
    window.location.href="/"
    
  };
  // const isAuthenticated = !!localStorage.getItem('userToken');
 return(<>
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
    fontSize: '2em',
    margin:'20px'
  }}>
Your Account need to be activated by a SuperAdmin
  </div>
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2em',
  }}>
 <Button onClick={handleLogout} sx={{ color: '#D8234C', ':hover': { backgroundColor: '#D8234C', color:'white',borderColor: '#D8234C' }, borderColor: '#D8234C' }}>Logout</Button>
 </div>
 </>

 )
}

export default WaitAdm;
