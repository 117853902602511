import React from 'react';
// import { Link, Route, Navigate } from 'react-router-dom';

function Settings() {
  // const isAuthenticated = !!localStorage.getItem('userToken');
 return(
  <>
  <br/><br/><br/>
  <h1>Settings Page!!!</h1>
  </>
 )
}

export default Settings;
