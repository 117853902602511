import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import * as jwt_decode from 'jwt-decode';

import { jwtDecode } from 'jwt-decode';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Register from './components/Register';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Settings from './components/Settings';
import MyProfile from './components/MyProfile';
import Navo from './components/Navo';
import UserExplorer from './components/UserExplorer';
import WaitAdm from './components/WaitAdm';
import Reports from './components/Reports';
import Meetings from './components/Meetings';
import User from './components/User';
import Logs from './components/Logs';
function App() {
  const isAuthenticated = !!localStorage.getItem('userToken');
  const [isActive, setIsActive] = useState(null);
  const [userData, setUserData] = useState(null);
  const isAlive = isAuthenticated ? localStorage.getItem("userToken"): null;
  // useEffect(() => {
  //   const userToken = localStorage.getItem('userToken');
  //   if (!userToken) return;
 
  //   const decoded = jwtDecode(userToken);
  //   setIsActive(decoded.Active);
  // }, []); 
 
  return (
    <>
    <BrowserRouter>
      { isAlive && <Navo /> }
      <Routes>
        <Route path="/" element={ isAlive ? ( <Dashboard /> ) : isAuthenticated ?(<WaitAdm/>) : ( <Login/> )}/>
        <Route path="/register" element={<Register />} />
        <Route path="/Dash" element={ isAlive ? ( <Dashboard /> ) : ( <Navigate to="/" replace /> )} />
        <Route path="/UserExplorer" element={ isAlive ? ( <UserExplorer /> ) : ( <Navigate to="/" replace /> )} />
        <Route path="/Reports" element={ isAlive ? ( <Reports /> ) : ( <Navigate to="/" replace /> )} />
        <Route path="/Meetings" element={ isAlive ? ( <Meetings /> ) : ( <Navigate to="/" replace /> )} />
        <Route path="/Settings" element={ isAlive ? ( <Settings /> ) : ( <Navigate to="/" replace /> )} />
        <Route path="/MyProfile" element={ isAlive ? ( <MyProfile /> ) : ( <Navigate to="/" replace /> )}/>
        <Route path="/Logs" element={ isAlive ? ( <Logs /> ) : ( <Navigate to="/" replace /> )}/>
        <Route path="/UserProfile/:id" element={ isAlive ? ( <User /> ) : ( <Navigate to="/" replace /> )}/>
      </Routes>
    </BrowserRouter>
      <ToastContainer
      position="top-left"
      autoClose={4000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      limit={5}
      // transition:Bounce
      />
      </>
  );
 }
 

export default App;
